import { Injectable } from '@angular/core';
import {
  FilterParameter,
  FilterParameterOperator,
} from '../interfaces/filter-parameter';
import { SearchProvider } from '../interfaces/search-provider';
import {
  ComplexSearchRequest,
  SearchOperand,
  SearchRequest,
} from '../interfaces/search-request';
import { SimpleSearchService } from './simple-search.service';

@Injectable({
  providedIn: 'root',
})
export class AdvancedSearchService implements SearchProvider {
  filterColumnSuffix = 'Filter';

  constructor(private simpleSearch: SimpleSearchService) {}

  validate(searchData: any): boolean {
    if (!searchData) {
      return false;
    }

    const keys = Object.keys(searchData);

    return keys.length >= 1;
  }

  createSearchRequest(
    searchData: Record<string, unknown>,
    filterParameters?: Record<string, FilterParameterOperator>,
    exactMatchFields: string[] = [],
  ): SearchRequest {
    if (!this.validate(searchData)) {
      return null;
    }

    const request: SearchRequest = {
      operand: SearchOperand.AND,
      filterParameters: [],
    };
    const keys = Object.keys(searchData);
    const enabledFilterKeys = keys
      .filter((key) => key.indexOf(this.filterColumnSuffix) > -1)
      .filter((filterKey) => searchData[filterKey])
      .map((enabledFilterKey) =>
        enabledFilterKey.replace(this.filterColumnSuffix, ''),
      );

    if (!enabledFilterKeys || enabledFilterKeys.length === 0) {
      return null;
    }

    enabledFilterKeys.forEach((key: string) => {
      const operator = exactMatchFields.includes(key)
        ? FilterParameterOperator.Equals
        : FilterParameterOperator.Contains;

      const control = searchData[key];
      const filterOperator = filterParameters
        ? filterParameters[key]
          ? filterParameters[key]
          : operator
        : operator;
      const filter: FilterParameter = {
        columnName: key,
        value: control,
        filterOperator,
      };

      request.filterParameters.push(filter);
    });

    return request;
  }

  doComplexSearch(complexSearchData: any[]): ComplexSearchRequest {
    if (!this.validate(complexSearchData)) {
      return null;
    }

    return {
      filters: complexSearchData.map((value) =>
        this.createSearchRequest(value),
      ),
    };
  }

  getFormattedSearchData(
    unformattedSearchData: string | Record<string, unknown>,
    searchColumn: string,
  ) {
    const isAdvancedSearch = typeof unformattedSearchData !== 'string';

    const canSearch = isAdvancedSearch
      ? this.validate(unformattedSearchData as Record<string, unknown>)
      : this.simpleSearch.canSearch(unformattedSearchData as string);

    if (canSearch) {
      const searchData = isAdvancedSearch
        ? this.createSearchRequest(
            unformattedSearchData as Record<string, unknown>,
          )
        : this.simpleSearch.doSearch(
            unformattedSearchData as string,
            searchColumn,
          );

      return searchData;
    }
    return null;
  }
}
